import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Divider, IconButton, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { tss } from 'tss-react';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { CREDITO_VALTELLINESE_INSTITUTE } from '../../models/InstituteModel';
import { hasProductField } from '../../models/ProductModel';
import { $PaperworkProcess, $PaperworkProcessSlice, DETAIL_STATE_MODEL } from '../../state/PaperworkProcessState';
import { hasInvestigationField } from '../../state/utils/hasInvestigationField';
import { minimumBrokerageAmountSuggested } from '../../state/utils/minimumBrokerageAmountSuggested';
import { brokeragePercentageView, MINIMUM_BROKERAGE_PERCENTAGE } from '../../view/brokeragePercentageView';
import { ltvView } from '../../view/ltvView';
import { CurrencyField } from '../atoms/CurrencyField';
import { FormikFormErrorsSummary } from '../atoms/FormikFormErrorsSummary';
import { InputField } from '../atoms/InputField';
import { SelectField } from '../atoms/SelectField';
import { Title } from '../atoms/Title';
import { NoteField } from '../molecules/NoteField';
import { RadioModalField } from '../molecules/RadioModalField';
const useStyles = tss.create(() => ({
    container: { padding: 12 },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    footer: {
        display: 'flex',
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        width: '432px',
    },
    content: { display: 'flex', flexDirection: 'column' },
}));
const BROKERAGE_PERCENTAGE = 'brokeragePercentage';
const SUGGESTED_BROKERAGE_AMOUNT = 'suggestedBrokerageAmount';
const LTV = 'ltv';
const PRODUCT = 'product';
const PROPERTY_VALUE = 'propertyValue';
const REQUESTED_AMOUNT = 'requestedAmount';
const BROKERAGE_AMOUNT = 'brokerageAmount';
const OUTSTANDING_PRINCIPAL_AMOUNT = 'outstandingPrincipalAmount';
const PROCESSING_FEE = 'processingFee';
const RENOVATION_AMOUNT = 'renovationAmount';
export function MortgageFormMainSection({ artooId, toggleDrawer, mortgageForm, initialValues, setMortgageForm, }) {
    const { classes, cx } = useStyles();
    const dispatch = useAppDispatch();
    const institutes = useAppSelector($PaperworkProcessSlice.selector.institutes);
    const purposes = useAppSelector($PaperworkProcessSlice.selector.purposes);
    const products = useAppSelector($PaperworkProcessSlice.selector.products);
    const loading = useAppSelector($PaperworkProcessSlice.selector.loading);
    const httpErrors = useAppSelector($PaperworkProcessSlice.selector.fieldErrors);
    const paperwork = useAppSelector($PaperworkProcessSlice.selector.paperwork);
    return (_jsx(Box, { className: classes.box, children: _jsxs("div", { className: classes.content, children: [_jsxs("div", { children: [_jsx("div", { className: classes.container, children: _jsxs("div", { className: classes.title, children: [_jsx(Typography, { variant: "h2", children: "Mutuo" }), _jsx(IconButton, { onClick: () => toggleDrawer(false), children: _jsx(CloseIcon, {}) })] }) }), _jsx(Divider, {})] }), _jsx(Formik, { initialValues: (mortgageForm === null || mortgageForm === void 0 ? void 0 : mortgageForm.mortgage) || initialValues, onSubmit: data => {
                        dispatch($PaperworkProcess.setDraft({ id: DETAIL_STATE_MODEL.MORTGAGE, value: true }));
                        setMortgageForm(previousForm => {
                            const updatedForm = previousForm === null
                                ? { mortgage: data }
                                : Object.assign(Object.assign({}, previousForm), { mortgage: Object.assign(Object.assign({}, previousForm.operationalData), data) });
                            if (updatedForm && paperwork) {
                                dispatch($PaperworkProcess.saveMortgageForm({
                                    paperwork: paperwork,
                                    mortgageFormData: updatedForm,
                                    products: products,
                                }));
                            }
                            return updatedForm;
                        });
                        toggleDrawer(false);
                    }, enableReinitialize: true, children: ({ handleBlur, values, setFieldValue, handleChange, errors, touched }) => (_jsx(Form, { children: _jsxs("div", { className: classes.container, children: [_jsx(Title, { children: "Prodotto: Mutuo" }), _jsx(SelectField, { label: "Tipologia di mutuo", name: "type", onChange: handleChange, onBlur: handleBlur, options: ['Mutuo', 'Prestito chirografario'], value: values.type, required: true, error: errors === null || errors === void 0 ? void 0 : errors.type, isTouched: touched === null || touched === void 0 ? void 0 : touched.type }), _jsx(SelectField, { label: "Gestione", name: "mortgageManagement", onChange: handleChange, onBlur: handleBlur, options: ['Accentrato', 'Decentrato'], value: values.mortgageManagement, error: errors === null || errors === void 0 ? void 0 : errors.mortgageManagement, isTouched: touched === null || touched === void 0 ? void 0 : touched.mortgageManagement }), _jsx(SelectField, { label: "Istituto", name: "institute", onChange: e => {
                                        var _a, _b;
                                        dispatch($PaperworkProcess.fetchProducts(Object.assign(Object.assign({ channel: ((_a = mortgageForm === null || mortgageForm === void 0 ? void 0 : mortgageForm.operationalData) === null || _a === void 0 ? void 0 : _a.channel) || '', institute: e.target.value, paperworkId: artooId }, (values.purpose !== '' && { purpose: values.purpose })), { productTypology: 'mortgage' })));
                                        dispatch($PaperworkProcess.fetchPurposes({
                                            channel: ((_b = mortgageForm === null || mortgageForm === void 0 ? void 0 : mortgageForm.operationalData) === null || _b === void 0 ? void 0 : _b.channel) || '',
                                            institute: e.target.value,
                                            paperworkId: artooId,
                                            productTypology: 'mortgage',
                                        }));
                                        setFieldValue(PRODUCT, '');
                                        handleChange(e);
                                    }, onBlur: handleBlur, options: institutes.map(institute => institute.name), value: values.institute, disabled: loading.institute, required: true, error: httpErrors.institute || (errors === null || errors === void 0 ? void 0 : errors.institute), isTouched: (touched === null || touched === void 0 ? void 0 : touched.institute) || httpErrors.institute !== undefined }), _jsx(SelectField, { label: "Finalit\u00E0", name: "purpose", onChange: e => {
                                        var _a;
                                        dispatch($PaperworkProcess.fetchProducts({
                                            channel: ((_a = mortgageForm === null || mortgageForm === void 0 ? void 0 : mortgageForm.operationalData) === null || _a === void 0 ? void 0 : _a.channel) || '',
                                            institute: values.institute,
                                            paperworkId: artooId,
                                            productTypology: 'mortgage',
                                            purpose: e.target.value,
                                        }));
                                        handleChange(e);
                                    }, onBlur: handleBlur, options: purposes, value: values.purpose, required: true, error: errors === null || errors === void 0 ? void 0 : errors.purpose, isTouched: touched === null || touched === void 0 ? void 0 : touched.purpose }), hasProductField(products, values.institute) && (_jsx(RadioModalField, { label: "Prodotto", name: PRODUCT, onBlur: handleBlur, options: products.map(product => product.name), value: values.product, disabled: loading.product, required: true, error: httpErrors.product || (errors === null || errors === void 0 ? void 0 : errors.product), isTouched: (touched === null || touched === void 0 ? void 0 : touched.product) || httpErrors.product !== undefined, setFieldValue: setFieldValue })), _jsx(CurrencyField, { fieldLabel: "Valore immobile", name: PROPERTY_VALUE, value: values.propertyValue, onBlur: handleBlur, onValueChange: ({ floatValue }) => {
                                        if (!floatValue) {
                                            return;
                                        }
                                        const ltv = ltvView(floatValue, values.requestedAmount);
                                        setFieldValue(LTV, ltv);
                                        setFieldValue(PROPERTY_VALUE, floatValue);
                                    }, required: true, error: errors === null || errors === void 0 ? void 0 : errors.propertyValue, isTouched: touched === null || touched === void 0 ? void 0 : touched.propertyValue }), _jsx(CurrencyField, { fieldLabel: "Importo richiesto", name: REQUESTED_AMOUNT, value: values.requestedAmount, onBlur: handleBlur, onValueChange: ({ floatValue }) => {
                                        var _a;
                                        if (!floatValue) {
                                            return;
                                        }
                                        setFieldValue(REQUESTED_AMOUNT, floatValue);
                                        const suggestedBrokeragePercentage = brokeragePercentageView(values.brokerageAmount, floatValue);
                                        setFieldValue(BROKERAGE_PERCENTAGE, suggestedBrokeragePercentage);
                                        const suggestedBrokerageAmount = minimumBrokerageAmountSuggested(((_a = mortgageForm === null || mortgageForm === void 0 ? void 0 : mortgageForm.operationalData) === null || _a === void 0 ? void 0 : _a.channel) || '', floatValue, values.brokerageAmount);
                                        setFieldValue(SUGGESTED_BROKERAGE_AMOUNT, suggestedBrokerageAmount);
                                        const ltv = ltvView(values.propertyValue, floatValue);
                                        setFieldValue(LTV, ltv);
                                        setFieldValue(BROKERAGE_PERCENTAGE, suggestedBrokeragePercentage);
                                    }, required: true, error: errors === null || errors === void 0 ? void 0 : errors.requestedAmount, isTouched: touched === null || touched === void 0 ? void 0 : touched.requestedAmount }), _jsx(CurrencyField, { fieldLabel: "Capitale residuo", name: OUTSTANDING_PRINCIPAL_AMOUNT, value: values.outstandingPrincipalAmount, onBlur: handleBlur, onValueChange: ({ floatValue }) => {
                                        if (!floatValue) {
                                            return;
                                        }
                                        setFieldValue(OUTSTANDING_PRINCIPAL_AMOUNT, floatValue);
                                    }, error: errors === null || errors === void 0 ? void 0 : errors.outstandingPrincipalAmount, isTouched: touched === null || touched === void 0 ? void 0 : touched.outstandingPrincipalAmount }), hasInvestigationField(products, values.product, values.institute) && (_jsx(CurrencyField, { fieldLabel: "Importo istruttoria", name: PROCESSING_FEE, value: values.processingFee, onBlur: handleBlur, onValueChange: ({ floatValue }) => {
                                        if (!floatValue) {
                                            return;
                                        }
                                        setFieldValue(PROCESSING_FEE, floatValue);
                                    }, required: true, error: errors === null || errors === void 0 ? void 0 : errors.processingFee, isTouched: touched === null || touched === void 0 ? void 0 : touched.processingFee })), _jsx(CurrencyField, { fieldLabel: "Importo ristrutturazione", name: RENOVATION_AMOUNT, value: values.renovationAmount, onBlur: handleBlur, onValueChange: ({ floatValue }) => {
                                        if (!floatValue) {
                                            return;
                                        }
                                        setFieldValue(RENOVATION_AMOUNT, floatValue);
                                    }, error: errors === null || errors === void 0 ? void 0 : errors.renovationAmount, isTouched: touched === null || touched === void 0 ? void 0 : touched.renovationAmount }), _jsx(CurrencyField, { fieldLabel: "Importo di mediazione", name: BROKERAGE_AMOUNT, value: values.brokerageAmount, onBlur: handleBlur, onValueChange: ({ floatValue }) => {
                                        var _a;
                                        if (!floatValue) {
                                            return;
                                        }
                                        setFieldValue(BROKERAGE_AMOUNT, floatValue);
                                        const brokeragePercentageValue = brokeragePercentageView(floatValue, values.requestedAmount);
                                        setFieldValue(BROKERAGE_PERCENTAGE, brokeragePercentageValue);
                                        const suggestedBrokerageAmount = minimumBrokerageAmountSuggested(((_a = mortgageForm === null || mortgageForm === void 0 ? void 0 : mortgageForm.operationalData) === null || _a === void 0 ? void 0 : _a.channel) || '', values.requestedAmount, floatValue);
                                        setFieldValue(SUGGESTED_BROKERAGE_AMOUNT, suggestedBrokerageAmount);
                                    }, required: true, error: errors === null || errors === void 0 ? void 0 : errors.brokerageAmount, isTouched: touched === null || touched === void 0 ? void 0 : touched.brokerageAmount, helperText: values.suggestedBrokerageAmount.toString() }), _jsx(InputField, { disabled: true, type: "number", label: "Percentuale di mediazione", name: BROKERAGE_PERCENTAGE, onChange: handleChange, value: values.brokeragePercentage, onBlur: handleBlur, endAdornmentLabel: "%", required: true, error: typeof values.brokeragePercentage === 'number' &&
                                        values.brokeragePercentage < MINIMUM_BROKERAGE_PERCENTAGE
                                        ? 'Percentuale di mediazione inferiore alla media soggetta ad autorizzazione DC'
                                        : errors === null || errors === void 0 ? void 0 : errors.brokeragePercentage, isTouched: true }), _jsx(SelectField, { label: "Tipo di immobile", name: "buildingType", onChange: handleChange, onBlur: handleBlur, options: [
                                        'Abitazione',
                                        'Bioedilizia',
                                        'Asta giudiziaria',
                                        'Uso commerciale',
                                        'Terreno',
                                        'Altro',
                                        'Abitativo',
                                        'Non abitativo',
                                    ], value: values.buildingType, error: errors === null || errors === void 0 ? void 0 : errors.buildingType, isTouched: touched === null || touched === void 0 ? void 0 : touched.buildingType }), _jsx(SelectField, { label: "Stato ricerca immobile", name: "searchStatus", onChange: handleChange, onBlur: handleBlur, options: [
                                        "Alla ricerca dell'immobile",
                                        'Individuato immobile',
                                        'Trattativa in corso',
                                        'Fatta proposta di acquisto',
                                        'Firmato compromesso',
                                        'Atto notarile fissato',
                                        'Immobile già di proprietà',
                                    ], value: values.searchStatus, error: errors === null || errors === void 0 ? void 0 : errors.searchStatus, isTouched: touched === null || touched === void 0 ? void 0 : touched.searchStatus }), _jsx(InputField, { disabled: true, type: "number", label: "LTV", name: LTV, onChange: handleChange, value: values.ltv, onBlur: handleBlur, endAdornmentLabel: "%", required: true, error: errors === null || errors === void 0 ? void 0 : errors.ltv, isTouched: touched === null || touched === void 0 ? void 0 : touched.ltv }), _jsx(InputField, { onChange: handleChange, value: values.location, onBlur: handleBlur, name: "location", label: "Localit\u00E0 immobile" }), _jsx(InputField, { onChange: handleChange, value: values.duration, onBlur: handleBlur, name: "duration", label: "Durata", endAdornmentLabel: "anni" }), _jsx(SelectField, { label: "Tipo di tasso", name: "rateType", onChange: handleChange, onBlur: handleBlur, options: [
                                        'Variabile',
                                        'Fisso',
                                        'Variabile Rata Costante',
                                        'Misto',
                                        'Con CAP',
                                        'Con Opzione',
                                    ], value: values.rateType, error: errors === null || errors === void 0 ? void 0 : errors.rateType, isTouched: touched === null || touched === void 0 ? void 0 : touched.rateType }), values.institute === CREDITO_VALTELLINESE_INSTITUTE && (_jsx(InputField, { type: "number", label: "Spread", name: "spread", onChange: handleChange, value: values.spread, onBlur: handleBlur, endAdornmentLabel: "%", required: true, error: errors === null || errors === void 0 ? void 0 : errors.spread, isTouched: touched === null || touched === void 0 ? void 0 : touched.spread })), _jsx(Title, { children: "Note" }), _jsx(NoteField, { handleBlur: handleBlur, handleChange: handleChange, value: values.notes }), _jsxs("div", { children: [_jsx("div", { className: classes.container, children: _jsx(FormikFormErrorsSummary, { httpErrors: httpErrors, errors: errors }) }), _jsx(Divider, {}), _jsxs("div", { className: cx(classes.container, classes.footer), children: [_jsx(Button, { sx: { marginRight: '8px' }, variant: "contained", type: "submit", children: "Continua a modificare" }), _jsx(Button, { onClick: () => toggleDrawer(false), children: "Annulla" })] })] })] }) })) })] }) }));
}
