export const participantTypeLabelView = (participantType) => {
    switch (participantType) {
        case 'applicant':
            return 'intestatario';
        case 'guarantor':
            return 'garante';
        case 'mortgager':
            return "datore d'ipoteca";
    }
};
