import * as t from 'io-ts';
export const instituteModel = t.type({
    name: t.string,
    affiliated: t.boolean,
});
export const CREDITO_VALTELLINESE_INSTITUTE = 'Credito Valtellinese';
export const isAnInAgreementInstitute = (instituteName) => !/^NON CONVENZIONE/.test(instituteName);
export const notInAgreementInstitutes = [
    { name: 'NON CONVENZIONE: Emil Banca', affiliated: false },
    { name: 'NON CONVENZIONE: BCC di Caraglio', affiliated: false },
    { name: 'NON CONVENZIONE: CR Asti', affiliated: false },
    { name: 'NON CONVENZIONE: BCC Bene Banca', affiliated: false },
    { name: 'NON CONVENZIONE: Banca Sparkasse', affiliated: false },
    { name: 'NON CONVENZIONE: Banca Intesa', affiliated: false },
    { name: 'NON CONVENZIONE: Volksbank', affiliated: false },
    { name: 'NON CONVENZIONE: UBI Banca', affiliated: false },
    { name: 'NON CONVENZIONE: Friulovest Banca', affiliated: false },
    { name: 'NON CONVENZIONE: Banca Popolare di Bari', affiliated: false },
    { name: 'NON CONVENZIONE: BCC di Cherasco', affiliated: false },
    { name: `NON CONVENZIONE: ${CREDITO_VALTELLINESE_INSTITUTE}`, affiliated: false },
    { name: 'NON CONVENZIONE: Banca BTL', affiliated: false },
    { name: 'NON CONVENZIONE: BIVER BANCA', affiliated: false },
    { name: 'NON CONVENZIONE: BCC di Binasco', affiliated: false },
    { name: 'NON CONVENZIONE: Banca Desio', affiliated: false },
    { name: 'NON CONVENZIONE: PRIMACASSA', affiliated: false },
    { name: 'NON CONVENZIONE: BCC BRIANZA E LAGHI', affiliated: false },
    { name: 'NON CONVENZIONE: Banca CRS', affiliated: false },
    { name: 'NON CONVENZIONE: Unicredit', affiliated: false },
    { name: 'NON CONVENZIONE: Banca del Veneto Centrale', affiliated: false },
    { name: 'NON CONVENZIONE: BCC Verona e Vicenza', affiliated: false },
    { name: 'NON CONVENZIONE: BCC di Staranzano e Villesse', affiliated: false },
    { name: 'NON CONVENZIONE: Banca della Marca', affiliated: false },
    { name: 'NON CONVENZIONE: BCC di Bari', affiliated: false },
    { name: 'NON CONVENZIONE: BANCA ALPI MARITTIME', affiliated: false },
    { name: 'NON CONVENZIONE: BCC di Santeramo in Colle', affiliated: false },
    { name: 'NON CONVENZIONE: Banca di Piacenza', affiliated: false },
    { name: 'NON CONVENZIONE: Banca del Piemonte', affiliated: false },
    { name: 'NON CONVENZIONE: BANCA VALSABBINA', affiliated: false },
];
