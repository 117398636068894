import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Divider, IconButton, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { tss } from 'tss-react';
import { Authorization } from '../../../authorization/Authorization';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { PERMISSION } from '../../../store/security/SecurityState';
import { $PaperworkProcess, $PaperworkProcessSlice, DETAIL_STATE_MODEL } from '../../state/PaperworkProcessState';
import { FormikFormErrorsSummary } from '../atoms/FormikFormErrorsSummary';
import { Title } from '../atoms/Title';
import { ChannelField } from '../molecules/ChannelField';
import { LendingSection } from '../molecules/LendingSection';
import { StoreField } from '../molecules/StoreField';
import { WarrantSection } from '../molecules/WarrantSection';
const useStyles = tss.create(() => ({
    container: { padding: 12 },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    footer: {
        display: 'flex',
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        width: '432px',
    },
    content: { display: 'flex', flexDirection: 'column' },
}));
export function MortgageOperationalDataFormSection({ artooId, toggleDrawer, mortgageForm, initialValues, setMortgageForm, }) {
    const { classes, cx } = useStyles();
    const dispatch = useAppDispatch();
    const paperwork = useAppSelector($PaperworkProcessSlice.selector.paperwork);
    const stores = useAppSelector($PaperworkProcessSlice.selector.stores);
    const channels = useAppSelector($PaperworkProcessSlice.selector.channels);
    const loading = useAppSelector($PaperworkProcessSlice.selector.loading);
    const httpErrors = useAppSelector($PaperworkProcessSlice.selector.fieldErrors);
    useEffect(() => {
        if (paperwork === null) {
            return;
        }
        dispatch($PaperworkProcess.fillForm({ paperwork: paperwork, productTypology: 'mortgage' }));
    }, [dispatch, paperwork]);
    return (_jsx(Box, { className: classes.box, children: _jsxs("div", { className: classes.content, children: [_jsxs("div", { children: [_jsx("div", { className: classes.container, children: _jsxs("div", { className: classes.title, children: [_jsx(Typography, { variant: "h2", children: "Dati operativi" }), _jsx(IconButton, { onClick: () => toggleDrawer(false), children: _jsx(CloseIcon, {}) })] }) }), _jsx(Divider, {})] }), _jsx(Formik, { initialValues: (mortgageForm === null || mortgageForm === void 0 ? void 0 : mortgageForm.operationalData) || initialValues, onSubmit: data => {
                        dispatch($PaperworkProcess.setDraft({ id: DETAIL_STATE_MODEL.OPERATIONAL_DATA, value: true }));
                        setMortgageForm(previousForm => {
                            const updatedForm = previousForm === null
                                ? { operationalData: data }
                                : Object.assign(Object.assign({}, previousForm), { operationalData: Object.assign(Object.assign({}, previousForm.operationalData), data) });
                            if (updatedForm && paperwork) {
                                dispatch($PaperworkProcess.saveMortgageForm({
                                    paperwork: paperwork,
                                    mortgageFormData: updatedForm,
                                    products: null,
                                }));
                            }
                            return updatedForm;
                        });
                        toggleDrawer(false);
                    }, enableReinitialize: true, children: ({ handleBlur, values, setFieldValue, handleChange, errors, touched }) => (_jsxs(Form, { children: [_jsxs("div", { className: classes.container, children: [_jsx(Title, { children: "Store di riferimento" }), _jsx(StoreField, { onBlur: handleBlur, value: values.store, inputValue: values.storeInput, isLoading: loading.store, setFieldValue: setFieldValue, stores: stores, error: httpErrors.store || errors.storeInput, isTouched: touched.storeInput || httpErrors.store !== undefined }), _jsxs(Authorization, { permissions: [PERMISSION.CAN_VIEW_CHANNEL_FIELD], children: [_jsx(Title, { children: "Rubinetto" }), _jsx(ChannelField, { channels: channels, context: { artooId: artooId, productTypology: 'mortgage' }, handleChange: handleChange, isLoading: loading.channel, setFieldValue: setFieldValue, valueInput: values.channelInput, onBlur: handleBlur, error: httpErrors.channel || errors.channelInput, isTouched: touched.channelInput || httpErrors.channel !== undefined, mortgage: (mortgageForm === null || mortgageForm === void 0 ? void 0 : mortgageForm.mortgage)
                                                    ? {
                                                        values: mortgageForm.mortgage,
                                                    }
                                                    : undefined })] }), _jsx(Title, { children: "Mandato" }), _jsx(WarrantSection, { setFieldValue: setFieldValue, warrantEndLabelValue: values.warrantEndLabel, warrantStartDateValue: values.warrantStartDate, handleBlur: handleBlur }), _jsx(Title, { children: "Finanziamento" }), _jsx(LendingSection, { setFieldValue: setFieldValue, values: values, handleBlur: handleBlur, handleChange: handleChange, errors: errors, touched: touched })] }), _jsxs("div", { children: [_jsx("div", { className: classes.container, children: _jsx(FormikFormErrorsSummary, { httpErrors: httpErrors, errors: errors }) }), _jsx(Divider, {}), _jsxs("div", { className: cx(classes.container, classes.footer), children: [_jsx(Button, { sx: { marginRight: '8px' }, variant: "contained", disabled: loading.paperwork, type: "submit", children: "Continua a modificare" }), _jsx(Button, { onClick: () => toggleDrawer(false), children: "Annulla" })] })] })] })) })] }) }));
}
