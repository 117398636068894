import { cqsDetailStateModelMapper } from './cqsDetailStateModelMapper';
import { mortgageDetailStateModelMapper } from './mortgageDetailStateModelMapper';
export const detailStateModelMapper = (unsavedFormData, paperwork, permissions, drafts, products) => {
    if (unsavedFormData) {
        switch (unsavedFormData.typology) {
            case 'cqs':
                return cqsDetailStateModelMapper(unsavedFormData.data, permissions);
            case 'mortgage':
                return mortgageDetailStateModelMapper(unsavedFormData.data, (paperwork === null || paperwork === void 0 ? void 0 : paperwork.data) || null, permissions, drafts, products);
        }
    }
    if (!paperwork) {
        return null;
    }
    switch (paperwork.productTypology) {
        case 'cqs':
            return cqsDetailStateModelMapper(paperwork.data, permissions);
        case 'mortgage':
            return mortgageDetailStateModelMapper(null, paperwork.data, permissions, drafts, products);
    }
};
