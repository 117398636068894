import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Container, Grid, Paper } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { $PaperworkProcess, $PaperworkProcessSlice } from '../../state/PaperworkProcessState';
import { Loader } from '../atoms/Loader';
import { CommissionCard } from '../molecules/CommissionCard';
import { Navbar } from '../molecules/Navbar';
import { Sidebar } from '../organisms/Sidebar';
import { PaperworkTabs } from './PaperworkTabs';
export const PaperworkPage = ({ activeTab }) => {
    const { paperworkId } = useParams();
    const paperwork = useAppSelector($PaperworkProcessSlice.selector.paperwork);
    const isPageLoading = useAppSelector($PaperworkProcessSlice.selector.isPageLoading);
    const initialValues = useAppSelector($PaperworkProcessSlice.selector.initialValues);
    const detail = useAppSelector($PaperworkProcessSlice.selector.detail);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch($PaperworkProcess.start({
            paperworkId: paperworkId,
        }));
        return () => {
            dispatch($PaperworkProcess.stop());
        };
    }, [dispatch, paperworkId]);
    return (_jsxs(_Fragment, { children: [isPageLoading && _jsx(Loader, {}), _jsxs(_Fragment, { children: [paperwork ? (_jsx(Navbar, { fullName: `${paperwork.data.nome} ${paperwork.data.cognome}`, phoneNumber: paperwork.data.telefono_cellulare, email: paperwork.data.email, nextActions: paperwork.details.nextActions })) : (_jsx(Navbar, { fullName: "", phoneNumber: "", email: "", nextActions: [] })), _jsx(Container, { sx: { marginTop: '32px' }, children: _jsxs(Grid, { container: true, item: true, flexWrap: "nowrap", children: [paperwork ? (_jsx(Sidebar, { paperwork: paperwork })) : (_jsx(Container, { component: Paper, elevation: 0, sx: {
                                        width: '690px',
                                        paddingTop: '16px',
                                        paddingBottom: '16px',
                                        minHeight: '100vh',
                                        marginRight: '16px',
                                    } })), _jsxs(Container, { maxWidth: "md", component: Paper, elevation: 0, sx: { paddingTop: '16px', paddingBottom: '16px', minHeight: '100vh' }, children: [_jsx(Container, { maxWidth: "sm", children: _jsx(CommissionCard, {}) }), paperwork && (_jsx(Box, { sx: { pt: '12px' }, children: _jsx(PaperworkTabs, { activeTab: activeTab, paperwork: paperwork, initialValues: initialValues, detail: detail }) }))] })] }) })] })] }));
};
