import { $number } from 'fortepiano';
import { option } from 'fp-ts';
export const _brokeragePercentageFromChannel = (channel) => {
    switch (channel) {
        case 'fmp':
            return 0.01;
        default:
            return 0.015;
    }
};
export const _suggestedBrokerageAmountOption = (channel, requestedAmount) => {
    if (isNaN(requestedAmount)) {
        return option.none;
    }
    const brokeragePercentage = _brokeragePercentageFromChannel(channel);
    return option.some($number.round(2)(requestedAmount * brokeragePercentage));
};
export const minimumBrokerageAmountSuggested = (channel, requestedAmount, brokerageAmount) => {
    if (typeof requestedAmount === 'string' || typeof brokerageAmount === 'string') {
        return '';
    }
    const minimumSuggestedBrokerageAmountOption = _suggestedBrokerageAmountOption(channel, requestedAmount);
    if (option.isSome(minimumSuggestedBrokerageAmountOption) &&
        minimumSuggestedBrokerageAmountOption.value > brokerageAmount) {
        return `Importo suggerito: almeno ${minimumSuggestedBrokerageAmountOption.value.toFixed(2)} euro`;
    }
    return '';
};
