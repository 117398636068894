import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Box, Button, Container, Drawer, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { $PaperworkProcess, $PaperworkProcessSlice, ALLOWED_MORTGAGE_APPLICANTS, ALLOWED_MORTGAGE_GUARANTORS, ALLOWED_MORTGAGE_MORTGAGERS, DETAIL_STATE_MODEL, FIRST_APPLICANT_INDEX, FIRST_GUARANTOR_INDEX, FIRST_MORTGAGER_INDEX, FOURTH_APPLICANT_INDEX, initialParticipantState, } from '../../state/PaperworkProcessState';
import { CardDetail } from '../molecules/CardDetail';
import { MortgageParticipantForm } from '../molecules/MortgageParticipantForm';
import { MortgageFormMainSection } from '../organisms/MortgageFormMainSection';
import { MortgageOperationalDataFormSection } from '../organisms/MortgageOperationalDataFormSection';
const onSubmit = (dispatch, paperworkData) => (formData) => {
    if (!paperworkData) {
        return;
    }
    dispatch($PaperworkProcess.updatePaperwork({
        productTypology: 'mortgage',
        formData: formData,
        dataModel: paperworkData,
    }));
};
const participantSetter = (participantIndex, setParticipant, mortgageForm) => {
    const participant = (mortgageForm === null || mortgageForm === void 0 ? void 0 : mortgageForm.participants) && (mortgageForm === null || mortgageForm === void 0 ? void 0 : mortgageForm.participants.find(({ index }) => index === participantIndex));
    if (participant === undefined) {
        return;
    }
    setParticipant(participant);
};
export const _getParticipantIndex = (fallbackIndex, participantType, mortgageFormParticipants = []) => {
    const participantSortedIndex = mortgageFormParticipants
        .filter(({ type }) => type === participantType)
        .map(({ index }) => index)
        .sort((indexA, indexB) => indexA - indexB);
    if (participantSortedIndex.length === 0) {
        return fallbackIndex;
    }
    for (let i = 0; i < participantSortedIndex.length; i++) {
        const previousIndex = participantSortedIndex[i - 1];
        if (previousIndex !== undefined && participantSortedIndex[i] !== previousIndex + 1) {
            return previousIndex + 1;
        }
    }
    const lastParticipantIndex = participantSortedIndex[participantSortedIndex.length - 1] || fallbackIndex;
    return lastParticipantIndex + 1;
};
const isParticipantButtonDisabled = (participantType, mortgageForm) => {
    const allowedParticipants = {
        applicant: ALLOWED_MORTGAGE_APPLICANTS,
        guarantor: ALLOWED_MORTGAGE_GUARANTORS,
        mortgager: ALLOWED_MORTGAGE_MORTGAGERS,
    };
    return (mortgageForm === null || mortgageForm === void 0 ? void 0 : mortgageForm.participants)
        ? mortgageForm.participants.filter(({ type }) => type === participantType).length >=
            allowedParticipants[participantType]
        : false;
};
const rearrangeParticipants = (participants) => {
    const applicants = [];
    const guarantors = [];
    const mortgagers = [];
    participants.forEach(item => {
        if (item.index >= FIRST_APPLICANT_INDEX && item.index <= FOURTH_APPLICANT_INDEX) {
            applicants.push(item);
        }
        else if (item.index >= FIRST_GUARANTOR_INDEX && item.index < FIRST_MORTGAGER_INDEX) {
            guarantors.push(item);
        }
        else if (item.index >= FIRST_MORTGAGER_INDEX) {
            mortgagers.push(item);
        }
    });
    return [
        ...applicants.map((applicant, newIndex) => (Object.assign(Object.assign({}, applicant), { index: newIndex }))),
        ...guarantors.map((guarantor, newIndex) => (Object.assign(Object.assign({}, guarantor), { index: newIndex + FIRST_GUARANTOR_INDEX }))),
        ...mortgagers.map((mortgager, newIndex) => (Object.assign(Object.assign({}, mortgager), { index: newIndex + FIRST_MORTGAGER_INDEX }))),
    ];
};
const onDelete = (mortgageForm, participantIndex, paperwork, products, dispatch) => () => {
    var _a, _b;
    const participants = rearrangeParticipants(((_a = mortgageForm.participants) === null || _a === void 0 ? void 0 : _a.filter(({ index }) => index !== participantIndex)) || []);
    const updatedForm = Object.assign(Object.assign({}, mortgageForm), { participants: participants });
    dispatch($PaperworkProcess.deleteParticipant({
        participantIndex: participantIndex,
        paperworkData: paperwork.data,
    }));
    const participantToDelete = (_b = mortgageForm.participants) === null || _b === void 0 ? void 0 : _b.find(({ index }) => index === participantIndex);
    if (participantToDelete !== undefined) {
        dispatch($PaperworkProcess.saveMortgageForm({
            mortgageFormData: updatedForm,
            paperwork: paperwork,
            products: products,
        }));
    }
};
export const MortgageDetail = ({ paperwork, initialValues, detail }) => {
    const [openMortgageDrawer, setOpenMortgageDrawer] = useState(false);
    const [openParticipantDrawer, setOpenParticipantDrawer] = useState(false);
    const [openOperationalDataDrawer, setOpenOperationalDataDrawer] = useState(false);
    const [mortgageForm, setMortgageForm] = useState(null);
    const [participant, setParticipant] = useState(null);
    const placeOfResidence = useAppSelector($PaperworkProcessSlice.selector.participantPlaceOfResidence);
    const products = useAppSelector($PaperworkProcessSlice.selector.products);
    const loading = useAppSelector($PaperworkProcessSlice.selector.loading);
    const dispatch = useAppDispatch();
    const draftById = useAppSelector($PaperworkProcessSlice.selector.draftById);
    const draft = useAppSelector($PaperworkProcessSlice.selector.draft);
    useEffect(() => {
        if (paperwork === null) {
            return;
        }
        dispatch($PaperworkProcess.fillForm({ paperwork: paperwork, productTypology: 'mortgage' }));
    }, [dispatch, paperwork]);
    useEffect(() => {
        setMortgageForm(initialValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(initialValues)]);
    return (_jsxs(Box, { children: [_jsx(Container, { maxWidth: "sm", sx: { marginTop: '16px' }, children: _jsx(CardDetail, { isDraft: id => draftById(id), onEdit: id => {
                        if (typeof id === 'number') {
                            participantSetter(id, setParticipant, mortgageForm);
                            setOpenParticipantDrawer(true);
                            return;
                        }
                        switch (id) {
                            case DETAIL_STATE_MODEL.OPERATIONAL_DATA:
                                setOpenOperationalDataDrawer(true);
                                break;
                            case DETAIL_STATE_MODEL.MORTGAGE:
                                setOpenMortgageDrawer(true);
                                break;
                        }
                    }, onDelete: index => {
                        if (!mortgageForm || typeof index !== 'number') {
                            return;
                        }
                        onDelete(mortgageForm, index, paperwork, products, dispatch)();
                    }, detail: detail }) }), initialValues && (_jsxs(_Fragment, { children: [_jsx(Drawer, { open: openOperationalDataDrawer, anchor: "right", children: _jsx(MortgageOperationalDataFormSection, { mortgageForm: mortgageForm, artooId: paperwork.details.artooId, toggleDrawer: setOpenOperationalDataDrawer, initialValues: initialValues.operationalData, setMortgageForm: setMortgageForm }) }), _jsx(Drawer, { open: openMortgageDrawer, anchor: "right", children: _jsx(MortgageFormMainSection, { artooId: paperwork.details.artooId, toggleDrawer: setOpenMortgageDrawer, mortgageForm: mortgageForm, initialValues: initialValues.mortgage, setMortgageForm: setMortgageForm }) }), _jsx(Drawer, { open: openParticipantDrawer, anchor: "right", children: participant && (_jsx(MortgageParticipantForm, { isResidenceLocationLoading: loading.participantPlaceOfResidence, placeOfResidence: placeOfResidence, toggleDrawer: setOpenParticipantDrawer, setMortgageForm: setMortgageForm, mortgageForm: mortgageForm, participantToAdd: participant })) })] })), _jsxs(Grid, { container: true, justifyContent: "flex-end", columnGap: "10px", rowGap: "10px", children: [_jsx(Button, { variant: "contained", disabled: isParticipantButtonDisabled('applicant', mortgageForm), startIcon: _jsx(AddRoundedIcon, {}), onClick: () => {
                            const index = _getParticipantIndex(FIRST_APPLICANT_INDEX, 'applicant', mortgageForm === null || mortgageForm === void 0 ? void 0 : mortgageForm.participants);
                            setParticipant(initialParticipantState('applicant', index));
                            setOpenParticipantDrawer(true);
                        }, children: "Intestatario" }), _jsx(Button, { variant: "contained", disabled: isParticipantButtonDisabled('guarantor', mortgageForm), startIcon: _jsx(AddRoundedIcon, {}), onClick: () => {
                            const index = _getParticipantIndex(FIRST_GUARANTOR_INDEX, 'guarantor', mortgageForm === null || mortgageForm === void 0 ? void 0 : mortgageForm.participants);
                            setParticipant(initialParticipantState('guarantor', index));
                            setOpenParticipantDrawer(true);
                        }, children: "Garante" }), _jsx(Button, { variant: "contained", disabled: isParticipantButtonDisabled('mortgager', mortgageForm), startIcon: _jsx(AddRoundedIcon, {}), onClick: () => {
                            const index = _getParticipantIndex(FIRST_MORTGAGER_INDEX, 'mortgager', mortgageForm === null || mortgageForm === void 0 ? void 0 : mortgageForm.participants);
                            setParticipant(initialParticipantState('mortgager', index));
                            setOpenParticipantDrawer(true);
                        }, children: "Datore d'ipoteca" })] }), _jsx(Grid, { sx: {
                    position: 'sticky',
                    bottom: 0,
                    paddingBottom: '16px',
                    marginTop: '8px',
                    paddingTop: '8px',
                    background: 'white',
                }, container: true, justifyContent: "center", children: _jsx(Button, { variant: "contained", style: { position: 'relative', right: 0 }, disabled: !Object.values(draft).some(isDraft => isDraft === true), onClick: () => {
                        if (!mortgageForm) {
                            return;
                        }
                        onSubmit(dispatch, paperwork.data)(mortgageForm);
                    }, children: "Salva" }) })] }));
};
