var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';
import { CqsDetail } from './CqsDetail';
import { DocumentationPage } from './DocumentationPage';
import { MortgageDetail } from './MortgageDetail';
const DOCUMENTATION_TAB = 1;
const PAPERWORK_DATA_TAB = 0;
function CustomTabPanel(props) {
    const { children, value, index } = props, other = __rest(props, ["children", "value", "index"]);
    return (_jsx("div", Object.assign({ role: "tabpanel", hidden: value !== index }, other, { children: value === index && _jsx(Box, { sx: { p: 3 }, children: children }) })));
}
export const PaperworkTabs = ({ paperwork, activeTab, initialValues, detail }) => {
    const [value, setValue] = React.useState(activeTab);
    const handleChange = (_, newValue) => {
        setValue(newValue);
    };
    return (_jsxs(Box, { sx: { width: '100%' }, children: [_jsx(Box, { sx: { borderBottom: 1, borderColor: 'divider' }, children: _jsx(Container, { maxWidth: "sm", children: _jsxs(Tabs, { variant: "fullWidth", value: value, onChange: handleChange, "aria-label": "basic tabs example", children: [_jsx(Tab, { label: "Dati pratica" }), _jsx(Tab, { label: "Documentazione" })] }) }) }), _jsxs(CustomTabPanel, { value: value, index: PAPERWORK_DATA_TAB, children: [paperwork.productTypology === 'cqs' && _jsx(CqsDetail, { paperwork: paperwork }), paperwork.productTypology === 'mortgage' && initialValues.mortgage !== null && detail && (_jsx(MortgageDetail, { detail: detail, paperwork: paperwork, initialValues: initialValues.mortgage }))] }), _jsx(CustomTabPanel, { value: value, index: DOCUMENTATION_TAB, children: _jsx(DocumentationPage, {}) })] }));
};
