import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useState } from 'react';
import { Prompt } from '../../../ui/Prompt/PromptComponent';
import { InputField } from '../atoms/InputField';
export const RadioModalField = ({ options, name, label, onBlur, value, setFieldValue, error = '', isTouched = false, required = false, disabled = false, }) => {
    const [selectedItem, setSelectedItem] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsx("span", { onClick: () => {
                    setIsOpen(true);
                }, children: _jsx(InputField, { required: required, name: name, label: label, disabled: disabled, onBlur: onBlur, onChange: () => { }, value: value, error: error, isTouched: isTouched }) }), _jsx(Prompt, { title: label, isOpen: isOpen, onCancelAction: () => setIsOpen(false), onConfirmAction: () => {
                    setFieldValue(name, selectedItem);
                    setIsOpen(false);
                }, children: _jsx(FormControl, { children: _jsx(RadioGroup, { children: options.map((option, key) => (_jsx(FormControlLabel, { value: option, control: _jsx(Radio, { onChange: e => {
                                    setSelectedItem(e.target.value);
                                }, checked: selectedItem === option }), label: option }, key))) }) }) })] }));
};
