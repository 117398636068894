import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Pagination } from '../../../common/ui/molecules/Pagination';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { $routes } from '../../../routes/routes';
import { $PaperworkList, $PaperworkListSlice } from '../../state/PaperworkListState';
import { paperworkIdView } from '../../view/PaperworkIdView';
import { Loader } from '../atoms/Loader';
const onClick = (paperworkId) => () => {
    window.open(`${$routes.PAPERWORKS}/${paperworkId}`, '_blank');
};
export const PaperworkTable = ({ table, isLoading }) => {
    const pagination = useAppSelector($PaperworkListSlice.selector.pagination);
    const appliedFilters = useAppSelector($PaperworkListSlice.selector.appliedFilters);
    const dispatch = useAppDispatch();
    return (_jsxs(_Fragment, { children: [isLoading && _jsx(Loader, {}), !isLoading && table && (_jsxs(_Fragment, { children: [_jsx(TableContainer, { component: Paper, elevation: 0, children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Nome" }), _jsx(TableCell, { children: "ID pratica" }), _jsx(TableCell, { children: "Stato" }), _jsx(TableCell, { children: "Creata il" }), _jsx(TableCell, { children: "In carico a" }), _jsx(TableCell, { children: "Prodotto" }), _jsx(TableCell, { children: "Istituto" }), _jsx(TableCell, { children: "Importo" }), _jsx(TableCell, { children: "Finalit\u00E0" })] }) }), _jsx(TableBody, { children: table.map(({ amount, assignedTo, createdAt, id, status, institute, name, product, purpose, }, key) => (_jsxs(TableRow, { onClick: onClick(id), sx: {
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                                cursor: 'pointer',
                                            },
                                        }, children: [_jsx(TableCell, { children: name }), _jsx(TableCell, { children: paperworkIdView(id) }), _jsx(TableCell, { children: status }), _jsx(TableCell, { children: createdAt }), _jsx(TableCell, { children: assignedTo }), _jsx(TableCell, { children: product }), _jsx(TableCell, { children: institute }), _jsx(TableCell, { children: amount }), _jsx(TableCell, { children: purpose })] }, key))) })] }) }), _jsx(Pagination, { previousPageCommand: () => dispatch($PaperworkList.fetchPreviousPage({ filter: appliedFilters })), nextPageCommand: () => dispatch($PaperworkList.fetchNextPage({ filter: appliedFilters })), firstPageCommand: () => dispatch($PaperworkList.fetchFirstPage({ filter: appliedFilters })), currentPage: pagination.currentPage, isNextPageDisabled: pagination.isNextPageDisabled, isPreviousPageDisabled: pagination.isPreviousPageDisabled })] }))] }));
};
