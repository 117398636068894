import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { $PaperworkProcess } from '../../state/PaperworkProcessState';
import { minimumBrokerageAmountSuggested } from '../../state/utils/minimumBrokerageAmountSuggested';
import { SelectField } from '../atoms/SelectField';
export const ChannelField = ({ isLoading, context, channels, handleChange, onBlur, setFieldValue, valueInput, error = '', isTouched, mortgage, }) => {
    const dispatch = useAppDispatch();
    return (_jsx(_Fragment, { children: _jsx(SelectField, { label: "Rubinetto", name: "channelInput", disabled: isLoading || channels.length === 0, onBlur: onBlur, onChange: e => {
                handleChange(e);
                const channel = channels.find(({ label }) => label === e.target.value);
                if (!channel) {
                    return;
                }
                dispatch($PaperworkProcess.fetchInstitutes({
                    channel: channel.value,
                    paperworkId: context.artooId,
                    productTypology: context.productTypology,
                }));
                if (context.institute) {
                    dispatch($PaperworkProcess.fetchProducts(Object.assign({ channel: channel.value, paperworkId: context.artooId, productTypology: context.productTypology, institute: context.institute }, (typeof context.purpose === 'string' && { purpose: context.purpose }))));
                }
                if (context.productTypology === 'mortgage' && context.institute) {
                    dispatch($PaperworkProcess.fetchPurposes(Object.assign({ channel: channel.value, paperworkId: context.artooId, productTypology: context.productTypology, institute: context.institute }, (typeof context.purpose === 'string' && { purpose: context.purpose }))));
                }
                setFieldValue('channel', channel.value);
                if (mortgage) {
                    const suggestedBrokerageAmount = minimumBrokerageAmountSuggested(channel.value, mortgage.values.requestedAmount, mortgage.values.brokerageAmount);
                    setFieldValue(suggestedBrokerageAmount, suggestedBrokerageAmount);
                }
            }, options: channels, value: valueInput, required: true, error: error, helperText: "La modifica di questo valore pu\u00F2 condizionare i campi relativi ai prodotti", isTouched: isTouched }) }));
};
