import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Box, Button, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Badge } from '../atoms/Badge';
import { ParticipantDeletion } from './ParticipantDeletion';
const useStyles = makeStyles()(() => ({
    mb8px: {
        marginBottom: '8px',
    },
    mt16px: {
        marginTop: '16px',
    },
    title: {
        color: '#6F797A',
    },
}));
export const CardDetail = ({ detail, onEdit, isDraft, onDelete }) => {
    const { classes } = useStyles();
    return detail.map(({ section, title: sectionTitle, id, hidden: hiddenDetail, canBeDeleted }, key) => !hiddenDetail && (_jsxs(Box, { children: [sectionTitle && (_jsx(Typography, { variant: "h3", sx: { mb: '8px' }, children: sectionTitle })), _jsx(Card, { elevation: 0, sx: {
                    mb: '16px',
                    '&.MuiPaper-root': id !== undefined && isDraft && isDraft(id)
                        ? { border: '2px solid  #FFDB58' }
                        : { border: '1px solid #F2F2F2' },
                }, children: _jsxs(CardContent, { children: [id !== undefined && isDraft && isDraft(id) ? (_jsxs(Grid, { container: true, alignItems: "center", justifyContent: "center", sx: { mb: '8px' }, children: [_jsx(Grid, { item: true, sx: { mr: '4px' }, children: _jsx(WarningAmberRoundedIcon, {}) }), _jsx(Grid, { item: true, children: _jsx(Typography, { children: "Ci sono delle modifiche non salvate" }) })] })) : null, section.map(({ title, badgeTitle, rows, hidden }, cardDetailKey) => hidden === true ? null : (_jsxs(React.Fragment, { children: [_jsxs(Grid, { sx: { mb: '4px' }, container: true, flexWrap: "nowrap", alignItems: "center", justifyContent: "space-between", children: [_jsx(Grid, { item: true, xs: 9, children: _jsx(Typography, { variant: "h4", className: classes.mb8px, children: title }) }), _jsxs(Grid, { container: true, justifyContent: "flex-end", flexWrap: "nowrap", children: [onEdit && (_jsx(Grid, { item: true, children: cardDetailKey === 0 && (_jsx(Button, { startIcon: _jsx(ModeEditOutlineOutlinedIcon, {}), size: "small", onClick: () => {
                                                            if (id === null || id === undefined) {
                                                                return;
                                                            }
                                                            onEdit(id);
                                                        }, children: "Modifica" })) })), canBeDeleted && onDelete && (_jsx(Grid, { item: true, children: cardDetailKey === 0 && (_jsx(ParticipantDeletion, { participantFullName: title, participantIndex: id, onDelete: () => {
                                                            if (id === undefined || id === null) {
                                                                return;
                                                            }
                                                            onDelete(id);
                                                        } })) }))] })] }), badgeTitle && (_jsx(Box, { className: classes.mb8px, children: _jsx(Badge, { title: badgeTitle, variant: "light" }) })), _jsx(Divider, {}), _jsx(Grid, { container: true, className: classes.mt16px, children: Object.entries(rows).map(([rowTitle, value], rowKey) => (_jsxs(Grid, { container: true, item: true, justifyContent: "space-between", className: classes.mb8px, children: [_jsx(Typography, { fontWeight: "500", className: classes.title, children: rowTitle }), value === null && _jsx(Badge, { title: "Da completare", variant: "grey" }), typeof value === 'string' && _jsx(Typography, { children: value }), typeof value === 'boolean' && value === false && (_jsx(CancelIcon, { fontSize: "small", sx: { color: '#B52528' } })), typeof value === 'boolean' && value === true && (_jsx(CheckCircleIcon, { fontSize: "small", sx: { color: '#00419D' } }))] }, rowKey))) })] }, cardDetailKey)))] }) })] }, key)));
};
