import { array, option } from 'fp-ts';
import { pipe } from 'fp-ts/function';
const FIRST_APPLICANT_INDEX = 0;
const SECOND_APPLICANT_INDEX = 1;
const THIRD_APPLICANT_INDEX = 2;
const FOURTH_APPLICANT_INDEX = 3;
const FIRST_GUARANTOR_INDEX = 4;
const SECOND_GUARANTOR_INDEX = 5;
const FIRST_MORTGAGER_INDEX = 6;
const SECOND_MORTGAGER_INDEX = 7;
const getPaperworkIndexOption = (participantIndex, partialKey) => {
    switch (participantIndex) {
        case FIRST_APPLICANT_INDEX:
            return option.some(partialKey);
        case SECOND_APPLICANT_INDEX:
            return option.some(`intestatario_0_${partialKey}`);
        case THIRD_APPLICANT_INDEX:
            return option.some(`intestatario_1_${partialKey}`);
        case FOURTH_APPLICANT_INDEX:
            return option.some(`intestatario_2_${partialKey}`);
        case FIRST_GUARANTOR_INDEX:
            return option.some(`garante_0_${partialKey}`);
        case SECOND_GUARANTOR_INDEX:
            return option.some(`garante_1_${partialKey}`);
        case FIRST_MORTGAGER_INDEX:
            return option.some(`datore_0_${partialKey}`);
        case SECOND_MORTGAGER_INDEX:
            return option.some(`datore_1_${partialKey}`);
        default:
            return option.none;
    }
};
const participantDataKeys = [
    'nome',
    'cognome',
    'codice_fiscale',
    'indirizzo_di_residenza',
    'localita_di_residenza',
    'cap_di_residenza',
    'provincia_di_nascita',
    'data_di_nascita',
    'sesso',
    'tipo_di_documento',
    'cittadinanza',
    'n_di_documento',
    'rilasciato_da',
    'luogo_di_rilascio',
    'data_di_rilascio',
    'data_di_scadenza',
    'telefono_cellulare',
    'telefono_fisso',
    'email',
    'stato_civile',
    'professione',
    'tipo_di_azienda',
    'partita_iva_datore_di_lavoro',
    'reddito_mensile_netto',
    'numero_di_mensilita',
    'anzianita_lavorativa',
    'componenti_nucleo_familiare',
    'consenso_invio_comunicazioni_promozionali_e_marketing',
    'consenso_cessione_dati_a_terzi',
];
const getParticipantKeysWithIndex = (participantIndex) => {
    return pipe(participantDataKeys, array.map(key => getPaperworkIndexOption(participantIndex, key)), array.compact);
};
export const _removeKeys = (keys, obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([key]) => !keys.includes(key)));
};
export const _replaceKeys = (currentKey, newKey, obj) => {
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => (key === newKey ? [currentKey, value] : [key, value])));
};
const isPartipantFirstNameAvailable = (participantIndex) => {
    const participantOption = pipe(getPaperworkIndexOption(participantIndex, 'nome'));
    return option.isSome(participantOption) && participantOption.value !== undefined;
};
export const _deleteParticipant = (participantIndex, paperworkDataModel) => {
    let paperworkData = {};
    const keysWithIndex = getParticipantKeysWithIndex(participantIndex);
    paperworkData = _removeKeys(keysWithIndex, paperworkDataModel);
    switch (participantIndex) {
        case FIRST_GUARANTOR_INDEX:
            if (isPartipantFirstNameAvailable(SECOND_GUARANTOR_INDEX)) {
                participantDataKeys.forEach(key => {
                    paperworkData = _replaceKeys(`garante_0_${key}`, `garante_1_${key}`, paperworkData);
                });
            }
            return paperworkData;
        case FIRST_MORTGAGER_INDEX:
            if (isPartipantFirstNameAvailable(SECOND_MORTGAGER_INDEX)) {
                participantDataKeys.forEach(key => {
                    paperworkData = _replaceKeys(`datore_0_${key}`, `datore_1_${key}`, paperworkData);
                });
            }
            return paperworkData;
        case FIRST_APPLICANT_INDEX:
            if (isPartipantFirstNameAvailable(SECOND_APPLICANT_INDEX)) {
                participantDataKeys.forEach(key => {
                    paperworkData = _replaceKeys(`${key}`, `intestatario_0_${key}`, paperworkData);
                });
            }
            if (isPartipantFirstNameAvailable(THIRD_APPLICANT_INDEX)) {
                participantDataKeys.forEach(key => {
                    paperworkData = _replaceKeys(`intestatario_0_${key}`, `intestatario_1_${key}`, paperworkData);
                });
            }
            if (isPartipantFirstNameAvailable(FOURTH_APPLICANT_INDEX)) {
                participantDataKeys.forEach(key => {
                    paperworkData = _replaceKeys(`intestatario_1_${key}`, `intestatario_2_${key}`, paperworkData);
                });
            }
            return paperworkData;
        case SECOND_APPLICANT_INDEX:
            if (isPartipantFirstNameAvailable(THIRD_APPLICANT_INDEX)) {
                participantDataKeys.forEach(key => {
                    paperworkData = _replaceKeys(`intestatario_0_${key}`, `intestatario_1_${key}`, paperworkData);
                });
            }
            if (isPartipantFirstNameAvailable(FOURTH_APPLICANT_INDEX)) {
                participantDataKeys.forEach(key => {
                    paperworkData = _replaceKeys(`intestatario_1_${key}`, `intestatario_2_${key}`, paperworkData);
                });
            }
            return paperworkData;
        case THIRD_APPLICANT_INDEX:
            if (isPartipantFirstNameAvailable(FOURTH_APPLICANT_INDEX)) {
                participantDataKeys.forEach(key => {
                    paperworkData = _replaceKeys(`intestatario_1_${key}`, `intestatario_2_${key}`, paperworkData);
                });
            }
            return paperworkData;
    }
    return paperworkData;
};
export const mortgagePaperworkMapper = (participantIndex, paperworkDataModel) => _deleteParticipant(participantIndex, paperworkDataModel);
