import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { toggle } from '../../../common/state/toggle';
import { Prompt } from '../../../ui/Prompt/PromptComponent';
import { FIRST_APPLICANT_INDEX, FIRST_GUARANTOR_INDEX, FIRST_MORTGAGER_INDEX, FOURTH_APPLICANT_INDEX, SECOND_APPLICANT_INDEX, SECOND_GUARANTOR_INDEX, SECOND_MORTGAGER_INDEX, THIRD_APPLICANT_INDEX, } from '../../state/PaperworkProcessState';
const getDialogTitle = (participantIndex) => {
    let title = 'Elimina ';
    switch (participantIndex) {
        case FIRST_APPLICANT_INDEX:
            title += 'primo intestatario';
            break;
        case SECOND_APPLICANT_INDEX:
            title += 'secondo intestatario';
            break;
        case THIRD_APPLICANT_INDEX:
            title += 'terzo intestatario';
            break;
        case FOURTH_APPLICANT_INDEX:
            title += 'quarto intestatario';
            break;
        case FIRST_GUARANTOR_INDEX:
            title += 'primo garante';
            break;
        case SECOND_GUARANTOR_INDEX:
            title += 'secondo garante';
            break;
        case FIRST_MORTGAGER_INDEX:
            title += "primo datore d'ipoteca";
            break;
        case SECOND_MORTGAGER_INDEX:
            title += "secondo datore d'ipoteca";
            break;
    }
    return title;
};
export const ParticipantDeletion = ({ onDelete, participantFullName, participantIndex }) => {
    const [showDeleteModal, setShowModal] = useState(false);
    return (_jsxs(_Fragment, { children: [showDeleteModal && (_jsx(Prompt, { isOpen: true, title: getDialogTitle(participantIndex), onCancelText: "Chiudi", onConfirmText: "Elimina", onCancelAction: toggle(setShowModal), onConfirmAction: () => {
                    onDelete();
                    setShowModal(false);
                }, children: _jsxs(Typography, { children: ["Sei sicuro di voler rimuovere ", participantFullName, "?"] }) })), _jsx(IconButton, { color: "primary", onClick: () => setShowModal(true), children: _jsx(DeleteOutlineRoundedIcon, {}) })] }));
};
