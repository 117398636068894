import { notInAgreementInstitutes } from '../../../models/InstituteModel';
export const institutesModelMapper = (productTypology) => (institutes) => {
    const institutesModel = institutes.map(institute => ({
        name: institute.name,
        affiliated: institute.affiliated,
    }));
    if (productTypology === 'mortgage') {
        institutesModel.concat(notInAgreementInstitutes);
    }
    return institutesModel;
};
