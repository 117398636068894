import { format } from 'date-fns';
import { numericFormatter } from 'react-number-format';
import { currencyFormatterRules } from '../utils/currencyFormatterRules';
const DATE_FORMAT = 'dd/MM/yyyy';
export const cqsDetailStateModelMapper = (cqsDataModel, permissions) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6;
    return [
        { section: [{ title: 'Store di riferimento', rows: { Store: cqsDataModel.store || null } }] },
        {
            hidden: !permissions.canViewChannel,
            section: [
                {
                    title: 'Rubinetto',
                    hidden: !permissions.canViewChannel,
                    rows: { Rubinetto: cqsDataModel.rubinetto },
                },
            ],
        },
        {
            section: [
                {
                    title: 'Mandato',
                    rows: {
                        'Data firma mandato': cqsDataModel.data_firma_mandato
                            ? format(cqsDataModel.data_firma_mandato, DATE_FORMAT)
                            : null,
                        'Data scadenza mandato': cqsDataModel.data_scadenza_mandato
                            ? format(cqsDataModel.data_scadenza_mandato, DATE_FORMAT)
                            : null,
                    },
                },
            ],
        },
        {
            section: [
                {
                    title: 'Finanziamento',
                    rows: {
                        'Data erogazione finanziamento': cqsDataModel.data_erogazione_finanziamento
                            ? format(cqsDataModel.data_erogazione_finanziamento, DATE_FORMAT)
                            : null,
                        'Mezzo di pagamento finanziamento': (_a = cqsDataModel.mezzo_di_pagamento_finanziamento) !== null && _a !== void 0 ? _a : null,
                    },
                },
            ],
        },
        {
            section: [
                {
                    title: 'Cessione del quinto',
                    rows: Object.assign(Object.assign({ Finalità: (_b = cqsDataModel.cessione_del_quinto_finalita) !== null && _b !== void 0 ? _b : null, 'Tipo di operazione': (_c = cqsDataModel.cessione_del_quinto_tipo_di_operazione) !== null && _c !== void 0 ? _c : null, 'Tipo di dipendente': (_d = cqsDataModel.cessione_del_quinto_tipo_di_dipendente) !== null && _d !== void 0 ? _d : null, Finanziaria: (_e = cqsDataModel.cessione_del_quinto_finanziaria) !== null && _e !== void 0 ? _e : null, Prodotto: (_f = cqsDataModel.cessione_del_quinto_prodotto) !== null && _f !== void 0 ? _f : null, 'Importo richiesto': cqsDataModel.importo_richiesto
                            ? `${numericFormatter(cqsDataModel.importo_richiesto.toString(), currencyFormatterRules)} €`
                            : null, TAN: cqsDataModel.cessione_del_quinto_taeg ? `${cqsDataModel.cessione_del_quinto_taeg}%` : null, 'Punti applicati': (_h = (_g = cqsDataModel.cessione_del_quinto_punti_applicati) === null || _g === void 0 ? void 0 : _g.toString()) !== null && _h !== void 0 ? _h : null, Durata: cqsDataModel.cessione_del_quinto_durata_mesi
                            ? `${cqsDataModel.cessione_del_quinto_durata_mesi} mesi`
                            : null, Rata: cqsDataModel.cessione_del_quinto_rata
                            ? `${numericFormatter(cqsDataModel.cessione_del_quinto_rata.toString(), currencyFormatterRules)} €`
                            : null, Montante: cqsDataModel.cessione_del_quinto_montante
                            ? `${numericFormatter(cqsDataModel.cessione_del_quinto_montante.toString(), currencyFormatterRules)} €`
                            : null }, (cqsDataModel.cessione_del_quinto_tipo_di_operazione === 'Rinnovo'
                        ? {
                            'Montante residuo': cqsDataModel.cessione_del_quinto_montante_residuo
                                ? `${numericFormatter(cqsDataModel.cessione_del_quinto_montante_residuo.toString(), currencyFormatterRules)} €`
                                : null,
                            'Delta montante': cqsDataModel.cessione_del_quinto_delta_montante
                                ? `${numericFormatter(cqsDataModel.cessione_del_quinto_delta_montante.toString(), currencyFormatterRules)} €`
                                : null,
                        }
                        : null)), { Note: (_j = cqsDataModel.note) !== null && _j !== void 0 ? _j : null }),
                },
            ],
        },
        {
            section: [
                {
                    title: `${cqsDataModel.nome} ${cqsDataModel.cognome}`,
                    rows: {
                        'Indirizzo di residenza': (_k = cqsDataModel.indirizzo_di_residenza) !== null && _k !== void 0 ? _k : null,
                        'Località di residenza': (_l = cqsDataModel.localita_di_residenza) !== null && _l !== void 0 ? _l : null,
                        'CAP di residenza': (_m = cqsDataModel.cap_di_residenza) !== null && _m !== void 0 ? _m : null,
                        'Comune di nascita': (_o = cqsDataModel.comune_di_nascita) !== null && _o !== void 0 ? _o : null,
                        'Provincia di nascita': (_p = cqsDataModel.provincia_di_nascita) !== null && _p !== void 0 ? _p : null,
                        'Data di nascita': cqsDataModel.data_di_nascita
                            ? format(cqsDataModel.data_di_nascita, DATE_FORMAT)
                            : null,
                        Sesso: (_q = cqsDataModel.sesso) !== null && _q !== void 0 ? _q : '',
                        'Codice fiscale': cqsDataModel.codice_fiscale,
                        'Tipo di documento': (_r = cqsDataModel.tipo_di_documento) !== null && _r !== void 0 ? _r : null,
                        Cittadinanza: (_s = cqsDataModel.cittadinanza) !== null && _s !== void 0 ? _s : null,
                        'N° di documento': (_t = cqsDataModel.n_di_documento) !== null && _t !== void 0 ? _t : null,
                        'Rilasciato da': (_u = cqsDataModel.rilasciato_da) !== null && _u !== void 0 ? _u : null,
                        'Luogo di rilascio': (_v = cqsDataModel.luogo_di_rilascio) !== null && _v !== void 0 ? _v : null,
                        'Data di rilascio': cqsDataModel.data_di_rilascio
                            ? format(cqsDataModel.data_di_rilascio, DATE_FORMAT)
                            : null,
                        'Data di scadenza': cqsDataModel.data_di_scadenza
                            ? format(cqsDataModel.data_di_scadenza, DATE_FORMAT)
                            : null,
                    },
                },
                {
                    title: 'Contatti',
                    rows: {
                        'Telefono cellulare': cqsDataModel.telefono_cellulare,
                        'Telefono fisso': (_w = cqsDataModel.telefono_fisso) !== null && _w !== void 0 ? _w : null,
                        Email: cqsDataModel.email,
                    },
                },
                {
                    title: 'Dati reddituali',
                    rows: {
                        'Stato civile': (_x = cqsDataModel.stato_civile) !== null && _x !== void 0 ? _x : null,
                        Professione: (_y = cqsDataModel.professione) !== null && _y !== void 0 ? _y : null,
                        'Tipo di azienda': (_z = cqsDataModel.tipo_di_azienda) !== null && _z !== void 0 ? _z : null,
                        'P.IVA datore di lavoro': (_0 = cqsDataModel.partita_iva_datore_di_lavoro) !== null && _0 !== void 0 ? _0 : null,
                        'Reddito mensile netto': cqsDataModel.reddito_mensile_netto
                            ? `${numericFormatter(cqsDataModel.reddito_mensile_netto.toString(), currencyFormatterRules)} €`
                            : null,
                        'Numero di mensilità': (_2 = (_1 = cqsDataModel.numero_di_mensilita) === null || _1 === void 0 ? void 0 : _1.toString()) !== null && _2 !== void 0 ? _2 : null,
                        'Anzianità lavorativa': cqsDataModel.anzianita_lavorativa
                            ? `${cqsDataModel.anzianita_lavorativa} mesi`
                            : null,
                        'Componenti nucleo familiare': (_4 = (_3 = cqsDataModel.componenti_nucleo_familiare) === null || _3 === void 0 ? void 0 : _3.toString()) !== null && _4 !== void 0 ? _4 : null,
                    },
                },
                {
                    title: 'Consensi',
                    rows: {
                        "Consenso per l'invio di comunicazioni promozionali e di marketing": (_5 = cqsDataModel.consenso_invio_comunicazioni_promozionali_e_marketing) !== null && _5 !== void 0 ? _5 : false,
                        'Consenso per la cessione di dati a terzi': (_6 = cqsDataModel.consenso_cessione_dati_a_terzi) !== null && _6 !== void 0 ? _6 : false,
                    },
                },
            ],
        },
    ];
};
